import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withRouter} from 'react-router';
import {getLanguage, getCountry, getCurrentLocale} from '../../utils/language';
import {setGenericEventClean} from '../../store/actions/dataLayer';
import './styles.css';
import {useTPPServices} from '../../tppServices/tppDIHooks';
import {isOuterClick} from '../../utils';

const Flag = ({country, lang, onClickFlag}) => {
  return (
    <a key={lang} onClick={(ev) => onClickFlag(ev, {action: 'country change', label: `${lang}`})} className="lang-item">
      <div className={`flag ${country}`} />
      {` ${getCountry(country)} - ${getLanguage(lang)}`}
    </a>
  );
};

Flag.propTypes = {
  country: PropTypes.string,
  lang: PropTypes.string,
  onClickFlag: PropTypes.func
};

const LanguagePicker = (props) => {
  const {tpp, configService} = useTPPServices();
  const context = configService.getConfig();
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (isOuterClick(e.target, containerRef.current)) {
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const locale = getCurrentLocale();
  const { pathname } = props.location;
  const languages = context.languages;
  const hideCurrentLanguage = !!(context.supports?.hideCurrentLanguage);
  const isTopNavigationV2Enabled = !!(context.supports?.enableTopNavigationV2);
  const dropDownText = isTopNavigationV2Enabled ? '' : '▼';
  const currentLanguage = {
    language: locale,
    country: languages[locale].country,
  };
  const pickerFlag = currentLanguage.country;
  const pickerText = `${getCountry(currentLanguage.country)} (${getLanguage(currentLanguage.language)}) ${dropDownText}`;
  const usesOldHeader = context.supports?.oldHeader;
  const headerClassnames = classnames('lang-wrapper', {'new-header': !usesOldHeader});
  const hideClassname = open ? '' : 'lang-menu-hide';

  const getAvailableLanguages = () => {
    const availableLanguages = [];
    for (const key in languages) {
      if (languages[key].hidden) {
        continue;
      }
      availableLanguages.push(key);
    }
    return availableLanguages;
  };

  const localizedLocation = getAvailableLanguages();

  const toggle = () => {
    setOpen(!open);
  };

  const onClickFlag = async (ev, event) => {
    ev.preventDefault();
    const flagLang = event.label?.toLowerCase();

    setGenericEventClean('top navbar', event.action, event.label);
    tpp.redirectToNewLanguage(flagLang, pathname);
  };

  return (
    <div ref={containerRef} onClick={toggle} className={headerClassnames} data-testid={'lang-picker'}>
      <div className="lang-switcher">
        <div className={`flag ${pickerFlag}`} />
        <span>{pickerText}</span>
      </div>
      <div className={`lang-menu ${hideClassname}`} >
        <div>
          {localizedLocation.map((lang, i) => {
            const {country} = languages[lang];
            if (!hideCurrentLanguage || currentLanguage.language !== lang) {
              return ( <Flag key={i} country={country} lang={lang} onClickFlag={onClickFlag} />  );
            }
          })}
        </div>
      </div>
    </div>
  );
};

LanguagePicker.propTypes = {
  currentLanguage: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
    url: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};
export default withRouter(LanguagePicker);
